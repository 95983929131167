

















































































































































import { Component, Vue } from 'vue-property-decorator';
import workspaceModule from '@/store/modules/workspaceModule';
import { getComponent } from '@/utils/helpers';
import scheduleModule from '@/store/modules/scheduleModule';
import accountModule from '@/store/modules/accountModule';
import Multiselect from 'vue-multiselect';

@Component({
  components: {
	  Dropdown: () => getComponent('common/Dropdown'),
	Multiselect,
  },
})
export default class PersonnelScheduleAddAssetRowModal extends Vue {
	allAssetTypes: any;
	assetTypeSelected = []
	assetSelected = []
	assetTypeOptions = [{text: "Well", value: "Well"},
	{text: "Pad", value: "Pad"},
	{text: "Route", value: "Route"},
	{text: "Area", value: "Area"}]
	dropdownTypeShowing = ''
	dropdownLocation: any[] = []


	getAssetsByType(type) {
		if (!this.allAssetTypes) {
			return []
		}
		if (type == "Well") {
			var wells: any = []
			var parsed_wells = JSON.parse(this.allAssetTypes["Wells"])
			for (var x = 0; x < parsed_wells.length; x++) {
				wells.push({text: parsed_wells[x], value: parsed_wells[x]})
			}
			return wells
		}
		if (type == "Pad") {
			var pads: any = []
			var parsed_pads = JSON.parse(this.allAssetTypes["Pads"])
			for (var x = 0; x < parsed_pads.length; x++) {
				pads.push({text: parsed_pads[x], value: parsed_pads[x]})
			}
			return pads
		}
		if (type == "Route") {
			var routes: any = []
			var parsed_routes = JSON.parse(this.allAssetTypes["Routes"])
			for (var x = 0; x < parsed_routes.length; x++) {
				routes.push({text: parsed_routes[x], value: parsed_routes[x]})
			}
			return routes
		}
		if (type == "Area") {
			var areas: any = []
			var parsed_areas = JSON.parse(this.allAssetTypes["Areas"])
			for (var x = 0; x < parsed_areas.length; x++) {
				areas.push({text: parsed_areas[x], value: parsed_areas[x]})
			}
			return areas
		}
		return []

	}

  async getAllAssetOptions(){
    this.allAssetTypes = await scheduleModule.getOperatorAssets();
  }

  async created() {
	  this.getAllAssetOptions()

  }

  get isSavingAddNewSchedule() {
	  return scheduleModule.isSavingAddNewSchedule
  }

  addButtonClicked() {
	  // @ts-ignore
	  this.$emit('add-row-button-clicked', this.assetTypeSelected[0].value, this.assetSelected[0].value)
  }

  hideDropdown() {
	  this.dropdownLocation = []
  }

  didSelectDropdownOption() {
	  this.dropdownLocation = []
  }


	didSelectAssetTypeDropdown(e) {
		const rect = e.srcElement.getBoundingClientRect()
		this.dropdownLocation = [rect.x - 0, rect.y + 38]
		this.dropdownTypeShowing = "AssetType"
	}

	didSelectAssetDropdown(e) {
		const rect = e.srcElement.getBoundingClientRect()
		this.dropdownLocation = [rect.x - 0, rect.y + 38]
		this.dropdownTypeShowing = "Asset"
	}





  closeButtonClicked() {
	  this.$emit("close-add-new-table-popup")
  }


}

